<template>
  <div class="grid">
    <div class="col-12">
      <div class="card">
        <div class="block_container">
          <div class="bloc1"><h3>Errores </h3></div>
        </div>
      </div>
    </div>

    <div class="col-12">
      <div class="card">
        <div class="block_container">
          <div class="bloc1">
            <TreeTable :value="nodes">
              <Column field="name" header="Name" :expander="true"></Column>
              <Column field="error" header="Error"></Column>
              <Column field="description" header="Descripcion"></Column>
            </TreeTable>
          </div>
        </div>
      </div>
    </div>


  </div>
</template>


<script>
export default {
  name: "system",
  data() {
    return {
      nodes: null
    }
  },
  nodeService: null,
  created() {

  },
  async mounted() {
    this.nodes = await this.getTreeTableNodes();
  },
  methods: {
    getTreeTableNodes() {
      return fetch('demo/data/treetablenodes.json').then(res => res.json())
          .then(d => d.root);
    }
  },
}

</script>

<style scoped>

</style>